export const dropdownOptions = [
  { value: '', description: 'Choose Layer' },
  { value: 'complainant_ethnicity', description: 'Complainant Ethnicity' },
  { value: 'complainant_gender', description: 'Complainant Gender' },
  { value: 'complainant_age_incident', description: 'Complainant Age' },
  { value: 'fado_type', description: 'Complaint Category' },
  { value: 'allegation', description: 'Specific Allegation' },
  { value: 'precinct', description: 'Officer Precinct' },
  { value: 'mos_age_incident', description: 'Officer Age at Incident' },
  { value: 'mos_gender', description: 'Officer Gender at Incident' },
  { value: 'rank_incident', description: 'Officer Rank at Incident' },
  { value: 'rank_now', description: 'Officer Rank Now' },
  { value: 'command_at_incident', description: 'Officer Command' },
  { value: 'contact_reason', description: 'Reason for Police Contact' },
  {
    value: 'outcome_description',
    description: 'Outcome of Police Interaction',
  },
  { value: 'board_disposition', description: 'CCRB Board Disposition' },
];
export const legend = {
  complainant_ethnicity: 'Complainant Ethnicity',
  complainant_gender: 'Complainant Gender',
  complainant_age_incident: 'Complainant Age',
  fado_type: 'Complaint Category',
  allegation: 'Specific Type',
  precinct: 'Officer Precinct',
  mos_age_incident: 'Officer Age at Incident',
  mos_gender: 'Officer Gender at Incident',
  rank_incident: 'Officer Rank at Incident',
  rank_now: 'Officer Rank Now',
  command_at_incident: 'Officer Command',
  contact_reason: 'Reason for Police Contact',
  outcome_description: 'Outcome of Police Interaction',
  board_disposition: 'CCRB Board Disposition',
};
